
import { DOMElement, useEffect, useRef, useState } from 'react';
import CalendarComponent from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import React from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';

import uuid from 'react-uuid'

import './calendarStyle.scss';
import style from './style.module.scss';
import moment from 'moment/moment';
import { getNextMonday } from '../../Pages/Settings/Imports/Clustering';

interface CalendarsProps {
    format?: string;
    onChange: any;
    initialValue?: Date;
    opened?: Boolean;
    onlyFuture?: Boolean;
    useSavedDate?: Boolean;
    weekly?: Boolean;
}

/**
 * 
 * @returns Login Page
 */
const Calendars = (props: CalendarsProps) => {

    let { format, onChange, initialValue, opened, onlyFuture, useSavedDate, weekly } = props;
    if(typeof initialValue !== 'object'){
        initialValue = new Date(initialValue);
    }

    const [date, setDate] = useState(initialValue || new Date());
    const [changed, setChanged] = useState(false);
    const [open, setOpen] = useState(false);
    const [hoveredDate, setHoveredDate] = useState(new Date());

    const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
    const [hoveredDateRange, setHoveredDateRange] = useState([null, null]);


    const containerRef = useRef();
    const calendarRef = useRef();

    useEffect(() => {
        if (initialValue && !changed) {
            console.log(weekly, initialValue)
            if (weekly) {
                const date = new Date(initialValue)
                let day = date.getDay()
                if (day === 0) {
                    day = 7;
                }
                date.setDate(date.getDate() - (day - 1));
                setSelectedDateRange([date, moment(date).add(6, 'd').toDate()]);
                setDate(date);
            } else {
                setDate(initialValue);
            }
        } else if (!initialValue && !changed && useSavedDate) {
            const savedDate = sessionStorage.getItem('savedDate');
            if (savedDate) {
                setDate(new Date(savedDate));
            }
        }
    }, [initialValue, changed, useSavedDate])

    useEffect(() => {
        window.addEventListener('click', detectClickOutsideProfile);
        return () => {
            window.removeEventListener('click', detectClickOutsideProfile);
        }
    }, [])

    useEffect(() => {
        if (weekly) {
            let day = date.getDay()
            if (day === 0) {
                day = 7;
            }
            date.setDate(date.getDate() - (day - 1));
            setSelectedDateRange([date, moment(date).add(6, 'd').toDate()]);

            setChanged(true);
            setDate(date);
            onChange(date);
        }
    }, [weekly])

    const changeDate = (date: Date) => {

        if (weekly && date) {
            let day = date.getDay()
            if (day === 0) {
                day = 7;
            }
            date.setDate(date.getDate() - (day - 1));
            setSelectedDateRange([date, moment(date).add(6, 'd').toDate()]);
        }

        setChanged(true);
        setDate(date);
        onChange(date);
        setOpen(false);
    }

    const detectClickOutsideProfile = (e: any) => {
        if (containerRef) {
            if (!containerRef?.current?.contains(e.target)) {
                setOpen(false);
            }
        }
    }

    const checkDisabled = (date: Date) => {
        if (!onlyFuture) {
            return false;
        }

        let checkDate = new Date();

        if (weekly) {
            checkDate = getNextMonday();
        }

        return moment(date).format('YYYY-MM-DD') < moment(checkDate).format('YYYY-MM-DD')
    }

    const hoverWeek = (date: Date) => {
        if (!weekly) {
            return;
        }
        const nextMonday = getNextMonday(date);
        const monday = moment(nextMonday).subtract(7, 'd');
        const sunday = moment(nextMonday).subtract(1, 'd');
        setHoveredDateRange([monday, sunday]);
    }
    const dehoverWeek = () => {
        if (!weekly) {
            return;
        }
        setHoveredDateRange([null, null]);
    }

    if (opened) {
        return <CalendarComponent
            showWeekNumbers={true}
            onChange={changeDate}
            tileDisabled={({ date }) => checkDisabled(date)}
            className={style.relative}
            value={date}
            view={'month'}
        />
    }

    return (
        <div
            className={`${style.container}`}
            onClick={() => {
                if (!open) {
                    setOpen(true);
                }
            }}
            ref={containerRef}
        >
            <p className='text-center'>
                <strong><Moment locale={'de'} date={date} format={format || "DD. MMMM"} /></strong>
                {
                    weekly
                    && <strong> - <Moment locale={'de'} date={date} add={{ d: 6 }} format={format || "DD. MMMM"} /></strong>
                }
            </p>
            <div className='calendar-container'>
                {open && <CalendarComponent
                    showWeekNumbers={true}
                    onChange={changeDate}
                    value={date}
                    onViewChange={() => {
                        return true;
                    }}
                    tileClassName={({ date }) => {

                        let classes = [];

                        if (weekly) {
                            classes.push('weekly');

                            if (isDateBetween(date, selectedDateRange[0], selectedDateRange[1])) {
                                classes.push('selected');

                                if (date.getDay() === 0) {
                                    classes.push('lastOfWeek');
                                } else if (date.getDay() === 1) {
                                    classes.push('firstOfWeek');
                                }
                            } else if (isDateBetween(date, hoveredDateRange[0], hoveredDateRange[1])) {
                                classes.push('hovered');

                                if (date.getDay() === 0) {
                                    classes.push('lastOfWeek');
                                } else if (date.getDay() === 1) {
                                    classes.push('firstOfWeek');
                                }
                            }
                        }

                        return classes;
                    }}
                    tileContent={({ date }) => {
                        return <div className={'hoverDetectOverlay'} onMouseEnter={() => hoverWeek(date)} onMouseLeave={dehoverWeek}></div>
                    }}
                    tileDisabled={({ date }) => checkDisabled(date)}
                    view={'month'}
                    ref={calendarRef}
                />}
            </div>

        </div>
    );
}

const isDateBetween = (date, min, max) => {
    return moment(date).format('YYYY-MM-DD') >= moment(min).format('YYYY-MM-DD') && moment(date).format('YYYY-MM-DD') <= moment(max).format('YYYY-MM-DD');
}

export default Calendars;
export { isDateBetween }